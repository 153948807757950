//@ts-check
import {getFirstEntryByType, isNumber} from '../utils/utils.js';
import {fixURL} from '../utils/consent.js';

const entryType = 'navigation';

/**
 * @param {import('../utils/utils.js').State} state
 */
export default function navStart([window, performance]) {
    const {document: {referrer}, location: {href}} = window;
    const {navigationStart = 0, fetchStart, domainLookupStart, domainLookupEnd, redirectStart, redirectEnd, connectStart, connectEnd, secureConnectionStart, requestStart, responseStart} = performance.timing;
    const navigation = /** @type {PerformanceNavigationTiming} */(getFirstEntryByType(performance, entryType));
    const result = {
        entryType: `${entryType}-start`,
        url: fixURL(href, window),
        referrer: fixURL(referrer, window),
        navigationType: navigation.type,
        protocol: navigation.nextHopProtocol,
        dns: domainLookupEnd - domainLookupStart,
        ...isNumber(fetchStart) && {fetchStart: fetchStart - navigationStart},
        ...redirectEnd && {redirect: redirectEnd - redirectStart},
        ...secureConnectionStart ? {
            tcp: secureConnectionStart - connectStart,
            ssl: connectEnd - secureConnectionStart
        } : {
            tcp: connectEnd - connectStart
        },
        ttfb: responseStart - navigationStart,
        response: responseStart - requestStart
    };
    return Promise.resolve(result);
}
